import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 先创建两个变量，然后将变量存储到get
    username: localStorage.getItem('username'),
    // userID: localStorage.getItem('userID')
  },
  getters: {

  },
  mutations: {
    loginOk(state, newName) {
      state.username = newName
      localStorage.setItem('username', newName)
    }
  },
  actions: {},
  modules: {}
})