// 引入axios
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {
  Toast
} from 'vant';



// 对axios的配置,必须在use前完成
// axios.默认配置,基础路径='...'//默认的服务器路径共用的
// axios.defaults.baseURL = "http://zhshici.lei-cloud.com/v1"
// axios.defaults.timeout = 5000
const service = axios.create({
  // baseURL: 'http://localhost:3000/'
  baseURL: "http://zhshici.lei-cloud.com/v1/",
  timeout: 5000 // 为已知需要花费很长时间的请求覆写超时设置
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // console.log(Date.now());
    if (localStorage.getItem("token") && localStorage.getItem("expiresIn") &&
      Date.now() < localStorage.getItem("expiresIn"))
      config.headers.token = localStorage.getItem("token");
    return config;
  },
  error => {
    // console.log(error);
    return Promise.reject(error);
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 接收后台参数状态，响应的数据全都将res=res.data
    const res = response.data;
    // 如果响应回来的数据编码是200，则响应成功
    if (res.code == 200) {
      return res;
    } else {
      // 根据返回的数据提示对应的数据，具体要看响应的接口
      console.log('拦截器打印错误:', res);
      if (res.data) {
        Toast.fail(res.data.desc);
      } else {
        Toast.fail(res.msg);
      }
      // 这里可以设置后台返回状态码是500或者是其他,然后重定向跳转
      if (res.code == 500) {
        router.push('/login')
      }
      return Promise.reject(res);
    }
  },
)
Vue.use(VueAxios, service)
Vue.use(Toast);