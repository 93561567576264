<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#app {
  font-family: "楷体";
  font-size: 16px;
  // padding-bottom: 50px;
}
</style>
