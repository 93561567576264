import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/search', //设置一个默认选定项
  },
  {
    path: '/write',
    name: 'write',
    // redirect: '/index',
    component: () => import('../views/Write/Index.vue'),
    meta: {
      title: '诗词中华-创作'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/ScSearch/ScSearch.vue'),
    meta: {
      title: '诗词中华-搜索',
      keepAlive: true
    },

  }, {
    path: '/scCardDetail',
    name: 'scCardDetail',
    component: () => import('../views/ScSearch/ScCardDetail.vue'),
    meta: {
      title: '诗词中华-搜索',

    },

  },

  {
    path: '/play',
    name: 'play',
    component: () => import('../views/Play/Play.vue'),
    meta: {
      title: '诗词中华-娱乐'
    }
  },
   {
     path: '/chengyujielong',
     name: 'plchengyujielongay',
     component: () => import('../views/Play/ChengYu.vue'),
     meta: {
       title: '诗词中华-成语接龙'
     }
   },
  {
    path: '/me',
    name: 'me',
    component: () => import('../views/Me/Me.vue'),
    meta: {
      title: '诗词中华-个人中心'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Me/Login.vue'),
    meta: {
      title: '诗词中华-登录'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Me/Register.vue'),
    meta: {
      title: '诗词中华-注册'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 防止编程式跳转报错
// 获取原型对象上的push函数,先保存一份
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法,重写push|replace
// 第一个参数:告诉原来的push方法,你往哪里跳转(传递那些参数)
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})
export default router